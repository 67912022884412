<template>
  <transition name="fade">
    <AuthTemplate class="login">
      <template slot="heading">
        {{$t('auth.Log in')}}
      </template>
      <template slot="heading-follower">
        <div class="mb-3">
          <span class="mr-1">
            {{$t('auth.New user?')}}
          </span>
          <TLink
            route="/register"
            :is-underlined="isLinkUnderlined"
            class="login__link auth__major-link"
          >
            {{$t('auth.Create an account')}}
          </TLink>
        </div>
      </template>
      <template slot="form">
        <form
          @submit.prevent="login"
          @keydown.enter="login"
        >
          <TInput
            v-model="formData.phone"
            v-validate="{
              required: true,
              regex: regex.phoneRegex,
            }"
            v-mask="$options.phoneMask"
            :error-messages="errors.collect('phone')"
            type="tel"
            name="phone"
            :label="$t('Phone number')"
            placeholder="+7 (000) 000-00-00"
            :disabled="isLoading"
            class="login__input"
          />
          <TInput
            v-model="formData.password"
            v-validate="{
              required: true,
              min: [6],
            }"
            :error-messages="errors.collect('password')"
            :type="isPasswordShown ? 'text' : 'password'"
            name="password"
            :label="$t('Password')"
            has-button
            :disabled="isLoading"
            class="mb-1 login__input login__input--password"
          >
            <TButton
              slot="button"
              transparent
              :icon="isPasswordShown ? 'eye' : 'eye-input'"
              :icon-fill="isPasswordShown ? 'none' : '#5E6979'"
              :icon-stroke="isPasswordShown ? '#5E6979' : 'none'"
              :icon-view-box="isPasswordShown ? '0 1 17 13' : '0 0 15 17'"
              style="position: absolute; right: 15px; width: 32px;"
              icon-width="15"
              icon-height="15"
              class="auth-template__password-btn"
              @click="showHidePassword"
            />
          </TInput>
          <div class="login__wrapper">
            <TLink
              route="/password-recovery"
              :is-underlined="false"
              :class="serverError ? 'mb-2' : 'mb-4'"
            >
              {{$t('auth.Forgot your password?')}}
            </TLink>
          </div>
          <div
            v-if="serverError"
            class="mb-3 error-text"
          >
            {{serverError}}
          </div>
          <TButton
            :loading="isLoading"
            type="submit"
            class="mb-4 login__button"
          >
            {{$t('auth.Login')}}
          </TButton>
        </form>
      </template>
    </AuthTemplate>
  </transition>
</template>

<script>
// Utils
import { regex, unmaskValue } from '~/assets/js/utils';

// Mixins
import formMixin from '~/assets/js/mixins/formMixin';

// Models
import loginModel from './models/login';

// Components
import AuthTemplate from '~/components/Auth/AuthTemplate';

export default {
  name: 'LoginForm',

  components: {
    AuthTemplate,
  },

  mixins: [
    formMixin,
  ],

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    loginError: {
      type: [Error, Object],
      default: null,
    },

    serverError: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    formData: loginModel(),
    regex,
    isPasswordShown: false,
    isLinkUnderlined: false,
  }),

  watch: {
    loginError: {
      handler(error) {
        this.addErrors(error);
      },
      deep: true,
    },
  },

  async mounted() {
    try {
      this.isLinkUnderlined = await document.fonts.ready.then(() => true);
    } catch (err) {
      console.log(err);
    }
  },

  methods: {
    unmaskValue,

    showHidePassword() {
      this.isPasswordShown = !this.isPasswordShown;
    },

    async login() {
      const isValid = await this.validateForm();

      if (!isValid) return;

      const params = {
        ...this.formData,
        phone: this.unmaskValue(this.formData.phone),
      };

      this.$emit('login', params);
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/_variables';

.login {
  &__wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__error {
    font-size: 1.3rem;
    line-height: 1.3;
  }
}

.login {
  &__input--password.t-field {
    padding-bottom: 0;
  }
}
</style>
